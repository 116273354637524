<template>
  <div class="about">
    <div class="quotes">
      <h2>
        "It is impossible to be both, selfish and happy"
      </h2>
      <i>- Joyce Meyer</i>
    </div>
    <div v-if="about.length" class="about-me">
      <div class="custom-shape-divider-top-1613284309">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
      <div class="description">
        <h2>About Me</h2>
        <p
          v-for="html in about[0].about"
          :key="html.key"
          align="left"
          class="tiga"
          v-html="html"
        ></p>
      </div>
      <div class="tools">
        <h2>Tools I've Been Work With</h2>
        <ul>
          <li v-for="tool in about[0].tools" :key="tool.key">{{ tool }}</li>
        </ul>
      </div>
    </div>
    <div v-if="about.length" class="photos">
      <div class="custom-shape-divider-top-1613289900">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
      <div class="photos-group">
        <img v-lazy="about[0].image" />
        <div>
          <h2>Wants to know more?</h2>
          <a :href="about[0].resume" target="_blank" rel="noreferrer noopener"
            >Click or Tap here to download my resume</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { db } from "../firestore";
export default {
  name: "About",
  components: {},
  data() {
    return {
      about: []
    };
  },
  firestore: {
    about: db.collection("about")
  }
};
</script>

<style scoped lang="scss">
.about {
  flex-grow: 1;
  /* max-height needed so it isn't grow larger prevent other element affected */
  max-height: 100%;
  overflow-y: scroll;
}
.quotes {
  display: flex;
  flex-direction: column;
  grid-area: quotes;
  justify-content: center;
  justify-self: center;
  margin: 0 40px;
  height: 100%;
  i {
    font-size: 20px;
  }
}
.custom-shape-divider-top-1613284309 {
  width: 100%;
  overflow: hidden;
  line-height: 0;
  fill: #f4f6f9;
  margin-bottom: 20px;
}

.about-me {
  background-color: var(--dark02);
  color: var(--light02);
  grid-area: about-me;
  display: flex;
  flex-direction: column;

  h2 {
    text-transform: uppercase;
    color: white;
    font-family: "Montserrat", sans-serif;
    text-decoration: underline solid var(--color00) 5px;
  }
  p,
  li {
    font-family: "Source Sans Pro", sans-serif;
  }
  div.description {
    flex: 1;
    padding: 40px 10% 0 10%;
  }
  div.tools {
    flex: 1;
    padding: 0 10% 40px 10%;
    font-size: 18px;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 0;
    }
    li {
      text-align: left;
      display: inline;
      flex: 30%;
      flex-grow: 0;
      padding-top: 10px;
    }
    li::before {
      content: "•";
      color: var(--color00);
    }
  }
}
.custom-shape-divider-top-1613289900 {
  width: 100%;
  overflow: hidden;
  line-height: 0;
  fill: var(--dark02);
  margin-bottom: 20px;
}
.photos {
  grid-area: photos;
  background-color: var(--color00);
  display: flex;
  flex-direction: column;
  align-items: center;
  .photos-group {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 0;
    overflow-x: hidden;
    img {
      border-top: 8px solid white;
      border-right: 8px solid var(--dark02);
      border-bottom: 8px solid var(--dark02);
      border-left: 8px solid white;
      height: auto;
      max-width: 400px;
      box-sizing: border-box;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    h2 {
      background-color: white;
    }
    a {
      color: white;
      background-color: black;
      border-radius: 20px;
      box-shadow: none;
      outline: none;
      border: none;
      padding: 7px;
      font-family: "Montserrat", sans-serif;
      cursor: pointer;
      text-decoration: none;
    }
    a:hover {
      background-color: var(--color07);
    }
  }
}

@media (min-width: 750px) {
  .about {
    overflow: visible;
    display: grid;
    flex-grow: 1;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 80% 1fr 1fr;
    grid-template-areas:
      "quotes quotes"
      "about-me about-me"
      "photos photos";
    .tools {
      li {
        flex: 20% !important;
        flex-grow: 0 !important;
      }
    }
  }
}

@media (min-width: 1000px) {
  .photos-group {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    img {
      margin-right: 30px;
    }
    div {
      margin-left: 30px;
    }
  }
}

@media (min-width: 1400px) {
  .photos-group {
    img {
      margin-right: 100px;
    }
    div {
      margin-left: 100px;
    }
  }
}
</style>
